import { Registration } from "../../services/janus/janus.types";
import { FieldNamesMarkedBoolean } from "react-hook-form";
import { RefObject } from "react";

export function nanoComponentShowError({
  ref,
  name,
  error,
}: {
  ref: RefObject<HTMLFormElement>;
  name: string;
  error: string;
}) {
  if (!ref.current) return;
  const element = ref.current.querySelector(
    `nano-input[name="${name}"]`
  ) as HTMLNanoInputElement | null;
  element?.showError(error);
}

type FormErrors = Partial<{
  [K in keyof Registration]: string;
}>;

export function getFormErrors({
  data,
  dirtyFields,
  isSubmitted,
}: {
  data: Registration | undefined;
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<Registration>>>;
  isSubmitted: boolean;
}): FormErrors {
  const errors = {} as FormErrors;

  if (!data) return errors;

  if (!isSubmitted) return errors;

  data?.actions.determined.combined_validation
    .filter(({ field }) => !dirtyFields[field])
    .forEach(
      ({ field, reason }) =>
        (errors[field] = `${errors[field] || ""}${
          errors[field] ? ", " : ""
        }${reason}`)
    );

  if (errors.password !== undefined)
    errors.password = "Please ensure your password follows the policy";

  return errors;
}

export function getConfirmPasswordError({
  password,
  confirmPassword,
}: {
  password: string | null;
  confirmPassword: string | null | undefined;
}) {
  if (password && password !== confirmPassword) {
    return "Password fields must match";
  }
  return "";
}

export function applyErrorsToForm(
  errors: undefined | FormErrors,
  ref: RefObject<HTMLFormElement>
) {
  if (!ref.current) return;
  if (!errors) return;

  const inputs = Array.from(
    ref.current.querySelectorAll(
      'nano-input:not([name="confirm_password"]), nano-checkbox-group'
    )
  ) as (HTMLNanoInputElement | HTMLNanoCheckboxGroupElement)[];
  inputs
    .map((input) => ({
      name: (input.getAttribute("name") ||
        input.getAttribute("data-name") ||
        "") as keyof Registration,
      input,
    }))
    .forEach(({ input, name }) => input.showError(errors[name] || ""));
}
