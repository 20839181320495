import { configureStore } from "@reduxjs/toolkit";
import { slice as scAddAccountSlice } from "../features/sc-add-account/sc-add-account.slice";
import { janusApi } from "../services/janus/janus.api";

export function getStore(preloadedState?: object) {
  return configureStore({
    reducer: {
      [scAddAccountSlice.name]: scAddAccountSlice.reducer,
      [janusApi.reducerPath]: janusApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: true,
        immutableCheck: true,
      }).concat(janusApi.middleware),
    preloadedState,
  });
}

export const store = getStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
