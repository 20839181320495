import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AddAccountSlice {
  id: string | undefined;
  token: string | undefined;
}

const initialState: AddAccountSlice = { id: undefined, token: undefined };

export const slice = createSlice({
  name: "scAddAccount",
  initialState,
  reducers: {
    setCredentials(state, action: PayloadAction<AddAccountSlice>) {
      state.id = action.payload.id;
      state.token = action.payload.token;
    },
  },
});

export default slice.reducer;
export const { setCredentials } = slice.actions;
