const defaults = {
  janusBaseUrl: "https://registration-ind.dig-dev.nanoporetech.com/api/",
};

if (process.env.NODE_ENV === "production") {
  if (!process.env.REACT_APP_JANUS_BASE_URL)
    throw new Error("REACT_APP_JANUS_BASE_URL is not defined");
}

export const janusBaseUrl =
  process.env.REACT_APP_JANUS_BASE_URL || defaults.janusBaseUrl;
