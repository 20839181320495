import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setCredentials } from "./sc-add-account.slice";
import { useGetRegistrationQuery } from "../../services/janus/janus.api";
import ScAddAccountForm from "./sc-add-account.form.ui";
import ScAddAccountLate from "./sc-add-account-late.ui";
import ScAddAccountEarly from "./sc-add-account-early.ui";
import ScAddAccountLoading from "./sc-add-account-loading.ui";

export default function ScAddAccount() {
  const { id, token } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCredentials({ id, token }));
  }, [id, token, dispatch]);

  const skip = id === undefined || token === undefined;
  const { data, isLoading } = useGetRegistrationQuery(id, { skip });

  if (isLoading) return <ScAddAccountLoading />;

  switch (data?.state) {
    case "MANUAL_CUSTOMER_CONFIRMATION":
      return <ScAddAccountForm />;

    case "MANUAL_COMPLETE":
      return <ScAddAccountLate />;

    default:
      return <ScAddAccountEarly />;
  }
}
