import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { Registration } from "./janus.types";
import { RootState } from "../../app/store";
import { janusBaseUrl } from "../environment";

export interface UpdateRegistrationPayload {
  id: string;
  data: Partial<Registration>;
}

interface TransitionRegistrationPayload {
  id: string;
  transitionName: string;
}

interface SetRegistrationPasswordPayload {
  id: string;
  password: string;
}

export const janusApi = createApi({
  reducerPath: "janusApi",
  tagTypes: ["registration"],
  baseQuery: fetchBaseQuery({
    baseUrl: janusBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      headers.set("X-JANUS-CALLBACK-TOKEN", state.scAddAccount.token || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getRegistration: builder.query<Registration, string | undefined>({
      query: (id) => ({ url: `registrations/${id}/` }),
      providesTags: ["registration"],
    }),
    updateRegistration: builder.mutation<
      Registration,
      UpdateRegistrationPayload
    >({
      query: (payload) => ({
        url: `registrations/${payload.id}/`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ["registration"],
    }),
    transitionRegistration: builder.mutation<
      void,
      TransitionRegistrationPayload
    >({
      query: (payload) => ({
        url: `registrations/${payload.id}/transitions/${payload.transitionName}/`,
        method: "POST",
      }),
      invalidatesTags: ["registration"],
    }),
    setPassword: builder.mutation<void, SetRegistrationPasswordPayload>({
      query: (payload) => ({
        url: `registrations/${payload.id}/set_password/`,
        method: "PATCH",
        body: { password: payload.password },
      }),
      invalidatesTags: ["registration"],
    }),
  }),
});

export const {
  useGetRegistrationQuery,
  useUpdateRegistrationMutation,
  useTransitionRegistrationMutation,
  useSetPasswordMutation,
} = janusApi;
