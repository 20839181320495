import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page";
import { store } from "./app/store";
import { Provider } from "react-redux";
import "@nanoporetech-digital/components/dist/nano-components/nano-components.css";
import "@nanoporetech-digital/components/dist/themes/nanopore.css";
import { defineCustomElements } from "@nanoporetech-digital/components/loader";
import ScAddAccount from "./features/sc-add-account/sc-add-account.ui";
import logo from "./app/logo.svg";

defineCustomElements(undefined, { resourcesUrl: "/" });

const router = createBrowserRouter([
  {
    path: "/sc/:id/:token",
    element: <ScAddAccount />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <main>
        <img src={logo} alt="Nanopore Logo" id="ont-logo" />
        <article>
          <RouterProvider router={router} />
        </article>
      </main>
    </Provider>
  </React.StrictMode>
);
